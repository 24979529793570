import * as React from 'react';
import { Close, Visibility, VisibilityOff, Warning } from '@mui/icons-material';
import {
	Box,
	CssBaseline,
	Grid,
	Link,
	TextField,
	Typography,
	FormControl,
	InputLabel,
	OutlinedInput,
	InputAdornment,
	IconButton,
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import jwt from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import MyRandomLogin from '../components/RandomImageLogin';
import LogoCard from '../components/cards/LogoCard';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';
import LanguajeButtonsInicio from '../components/navbar/LanguajeButtonsInicio';
import { useLocation } from 'react-router-dom';

/*Descripción:Este componente se encarga de deplegar el formulario de ingreso a el portal
Componente padre:
Componente hijo: Inicio.jsx //
	MyRandomLogin
props: Sin uso de props
*/

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

// Funcion para mostrar los derechos de autor del portal
function Copyright(props) {
	// Funcion para traducir la pagina en español o ingles
	return (
		<Typography
			variant='body2'
			color='text.secondary'
			align='center'
			{...props}
		></Typography>
	);
}

export default function Login() {
	//  Para saber si el token es válido o no
	const location = useLocation();
	// Funcion para traducir la pagina en español o ingles
	const { t } = useTranslation();
	// Se declaran los const para su uso en el componente
	const cookies = new Cookies();
	const navigate = useNavigate();
	const [open, setOpen] = useState(false);
	const [user, setUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(t('alertas.usuario'));
	// Mostrar contraseña
	const [showPassword, setShowPassword] = useState(false);
	const [state] = useState({
		vertical: 'bottom',
		horizontal: 'right',
	});
	const { vertical, horizontal } = state;
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	// Llamamos a handleAlertDuration cuando el componente se monta para iniciar el temporizador
	useEffect(() => {
		handleAlertDuration();
	}, [open]);

	// Funcion para asignar el rol al terminar el Login
	const login2 = (jwt_token) => {
		const decoded = jwt(jwt_token);
		setUser(decoded);
		cookies.set('jwt_authorization', jwt_token, {
			expires: new Date(decoded.exp * 1000), // Tiempo de expiración de la cookie, que es igual al tiempo de expiración del token
		});
	};

	// Se hace el Data Fetch
	const GetToken = async (username, password) => {
		setLoading(true);
		var myHeaders = new Headers();
		myHeaders.append('acces', '');
		myHeaders.append('Content-Type', 'application/json');
		var raw = JSON.stringify({
			user: username,
			password: password,
		});
		var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw,
			redirect: 'follow',
		};
		// Se hace la peticion al endpoint
		try {
			const response = await fetch(
				process.env.REACT_APP_API_ACCUTRACK_URL + 'login',
				requestOptions
			);
			// Se asigna la información del API
			const data = await response.json();
			// Se valida la respuesta del API
			if (response.status === 200) {
				navigate('/inicio');
				login2(data.accessToken);
			} else if (response.status === 401) {
				navigate('/', { state: process.env.REACT_APP_ONE_VALID_TOKEN });
			} else {
				setError(t('alertas.usuario'));
				setOpen(true);
				setLoading(false);
			}
		} catch (error) {
			setError(t('alertas.errorInicioSesion'));
			setOpen(true);
			setLoading(false);
		}
	};

	// Mostrar mensaje de token inválido
	useEffect(() => {
		if (location.state) {
			setError(t('alertas.sesionExpirada'));
			setOpen(true);
			setLoading(false);
		}
	}, []);

	// Funcion para el submit y obtener el token
	const handleSubmit = (event) => {
		event.preventDefault();
		const data = new FormData(event.currentTarget);
		GetToken(data.get('email'), data.get('password'));
	};

	// Envia al dashboard inicial si la creación del token fué correcta==
	const redirectToView = () => {
		navigate('/inicio');
	};

	// Esta función cierra el Alert estableciendo el estado a falso
	const handleClose = () => {
		setOpen(false);
	};

	// Esta función maneja el temporizador y cierra el Alert después de cierto tiempo
	const handleAlertDuration = () => {
		setTimeout(() => {
			if (open) {
				handleClose();
			}
		}, 7000);
	};

	return (
		<Grid container component='main' sx={{ height: '100vh' }}>
			<CssBaseline />
			<MyRandomLogin />
			<Grid
				item
				xs={12}
				sm={8}
				md={5}
				lg={5}
				xl={4}
				component={Paper}
				elevation={6}
				square
			>
				<Grid
					container
					paddingTop={1}
					justifyContent='flex-end'
					paddingRight={1}
				>
					<LanguajeButtonsInicio />
				</Grid>
				<Box
					sx={{
						my: '12vh',
						mx: 4,
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
					}}
				>
					<LogoCard />
					{user ? (
						redirectToView()
					) : (
						<Typography variant='h5' marginTop={1}>
							{t('inicio.ingresar')}
						</Typography>
					)}
					<Box
						component='form'
						noValidate
						onSubmit={handleSubmit}
						sx={{ mt: 0.5 }}
					>
						<TextField
							margin='normal'
							required
							fullWidth
							id='email'
							label={t('inicio.usuario')}
							name='email'
							autoComplete='email'
							autoFocus
							className='margin-bottom-29'
						/>
						<FormControl variant='outlined' sx={{ width: '100%' }}>
							<InputLabel htmlFor='outlined-adornment-password'>
								{t('inicio.contrasena')}
							</InputLabel>
							<OutlinedInput
								id='password'
								name='password'
								type={showPassword ? 'text' : 'password'}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={
												handleMouseDownPassword
											}
											edge='end'
										>
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								}
								label='Contraseña'
							/>
						</FormControl>
						<LoadingButton
							loading={loading}
							id='login-button'
							type='submit'
							fullWidth
							variant='contained'
							sx={{
								mt: 3,
								mb: 2,
								backgroundColor: '#002D58',
							}}
						>
							{t('inicio.ingresar')}
						</LoadingButton>

						<Alert
							severity='error'
							icon={<Warning fontSize='inherit' />}
							paddingBottom={1}
						>
							Por actualizaciones de parte de Microsoft Cloud, el
							portal puede presentar problemas de acceso.
						</Alert>
						{open && (
							<Snackbar
								open={open}
								autoHideDuration={10000}
								anchorOrigin={{ vertical, horizontal }}
							>
								<Alert
									action={
										<IconButton
											aria-label='close'
											color='inherit'
											size='small'
											onClick={() => {
												setOpen(false);
											}}
										>
											<Close fontSize='inherit' />
										</IconButton>
									}
									sx={{ mb: 2, width: 'auto' }}
									severity='error'
								>
									{error}
								</Alert>
							</Snackbar>
						)}
						<Grid container>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link href='/contactanos' variant='body2'>
									<Typography variant='body2'>
										{t('inicio.apoyo')}
									</Typography>
								</Link>
							</Grid>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link
									href='/user/recupera-contrasena'
									variant='body2'
								>
									<Typography variant='body2'>
										{t('inicio.olvido')}
									</Typography>
								</Link>
							</Grid>
							<Grid
								container
								sx={{
									justifyContent: 'space-evenly',
									alignItems: 'center',
								}}
							>
								<Link href='/help' variant='body2'>
									<Typography variant='body2'>
										{t('centroAyuda.titulo')}
									</Typography>
								</Link>
							</Grid>
						</Grid>
						<Copyright sx={{ mt: 5 }} />
					</Box>
				</Box>
			</Grid>
		</Grid>
	);
}
